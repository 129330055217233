import { useAppState, getAppContext } from './substantiate';
import { AppState, assert, Tool } from './types';
import { AppAction, _dispatch } from './dispatch';
import { useCallback, useEffect, useState } from 'react';
import { Header } from './Header';
import { usePreventWholePageScrollingOnMobile } from './preventScrollingOnMobileExceptFor';
import { useElementSize } from './useElementSize';
import { useWindowViewportSize } from './useWindowViewportSize';
import { storeAccessKeyIdIfExists } from './authentication';
import { useAuthenticationStatusAndLoadedState } from './useAuthenticationStatusAndLoadedState';
import { isMobileOrTablet } from './isMobileOrTablet';
import { getNotLoadedYetMessageIfLoading } from './getNotLoadedYetMessageIfLoading';
import { ChatMainPage } from './ChatMainPage';
import { CustomizePage } from './CustomizePage';
import { PageSwitchButton } from './PageSwitchButton';
import { EssaysMainPage } from './EssaysMainPage';
import { PrimersMainPage } from './PrimersMainPage';
import 'prosemirror-view/style/prosemirror.css';
import './App.css';
console.clear();

// Basic approach to layout:
//
// * Whole layout is regular static CSS layout
//   * Height is fixed to the visible height of the viewport. Can't use vh
//     because that doesn't take the mobile keyboard into account.
//   * Width is set to something between minContentWidth and maxContentWidth.
//     * useElementSize watches the document and sets the correct contentWidth
//       when it changes.
//     * History modal width is similarly driven by a useElementSize on the
//       textarea.
//   * Centered with auto x margin.
// * Header just sits at top as normal
// * Messages
//   * Fixed below bottom of elementAboveMessages and given rest of
//     visibleHeight which makes it take up remaining space and also scroll.
// * We lock down scrolling on mobile with preventWholePageScrollingOnMobile()
//   to avoid the layout moving with touches.

// Basic approach to storage:
//
// * Everything is stored in localStorage.  Nothing is persisted to the server except tools.
// * On page load, localStorage state is loaded and tools are loaded from PRODUCTION (even on dev).
//   This allows me to iterate on tools in both dev and prod.
// * On every local state change, tools are persisted to prod.

storeAccessKeyIdIfExists();

function AppWithLoadedState({ loadedState }: { loadedState: AppState }) {
  const AppContext = getAppContext<AppState, AppAction>();
  const { state, dispatch } = useAppState(_dispatch, loadedState);
  const currentTool =
    state.tools.find((tool) => tool.name === state.toolName) || state.tools[0];

  const [inputTextarea, setInputTextarea] =
    useState<HTMLTextAreaElement | null>(null);

  const [isConfigShowing, setIsConfigShowing] = useState(false);

  const visibleHeight = useWindowViewportSize();

  const minContentWidth = currentTool.name === 'essays' ? 600 : 375;
  const maxContentWidth = currentTool.name === 'essays' ? 800 : 550;

  const [contentWidth, setContentWidth] = useState(document.body.clientWidth);
  const [documentWidth, setDocumentWidth] = useState(document.body.clientWidth);
  useElementSize(document.body, (rect) => setDocumentWidth(rect.width));
  useEffect(
    () =>
      setContentWidth(
        documentWidth > maxContentWidth
          ? maxContentWidth
          : documentWidth < minContentWidth
          ? minContentWidth
          : documentWidth
      ),
    [currentTool.name, documentWidth, maxContentWidth, minContentWidth]
  );

  const focusInputTextareaIfOnDesktop = useCallback(() => {
    if (!inputTextarea || isMobileOrTablet()) {
      return;
    }

    inputTextarea.focus();
    inputTextarea.setSelectionRange(
      inputTextarea.selectionStart,
      inputTextarea.selectionEnd
    );
  }, [inputTextarea]);

  // On load on desktop, focus the textarea and set the cursor to the end of the input
  useEffect(focusInputTextareaIfOnDesktop, [focusInputTextareaIfOnDesktop]);

  usePreventWholePageScrollingOnMobile(
    // TODO: Can't get customize-page to scroll - try setting scroll behavior in
    // css on customizePage div like divs around messages div and story div
    ['messages', 'customize-page', 'story'],
    ['TEXTAREA'],
    visibleHeight,
    window.innerHeight
  );

  const onStartNewConversation = useCallback(() => {
    dispatch({ type: 'startNewConversation', toolName: currentTool.name });
    focusInputTextareaIfOnDesktop();
  }, [dispatch, focusInputTextareaIfOnDesktop, currentTool.name]);

  // -16 for (in effect) extra px1 on messages inside main content
  const pageContentWidth = contentWidth - 16;

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <div
        style={{
          height: visibleHeight,
          width: contentWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div className="flex flex-column height-full">
          <Header currentTool={currentTool} />

          <div className="flex justify-end pt1 pr1">
            <PageSwitchButton
              isConfigShowing={isConfigShowing}
              setIsConfigShowing={setIsConfigShowing}
            />
          </div>

          {!isConfigShowing ? (
            <MainPage
              currentTool={currentTool}
              visibleHeight={visibleHeight}
              pageContentWidth={pageContentWidth}
              inputTextarea={inputTextarea}
              setInputTextarea={setInputTextarea}
              onStartNewConversation={onStartNewConversation}
            />
          ) : (
            <CustomizePage
              visibleHeight={visibleHeight}
              contentWidth={pageContentWidth}
              currentTool={currentTool}
            />
          )}
        </div>
      </div>
    </AppContext.Provider>
  );
}

function MainPage({
  currentTool,
  visibleHeight,
  pageContentWidth,
  inputTextarea,
  setInputTextarea,
  onStartNewConversation,
}: {
  currentTool: Tool;
  visibleHeight: number;
  pageContentWidth: number;
  inputTextarea: HTMLTextAreaElement | null;
  setInputTextarea: (textarea: HTMLTextAreaElement | null) => void;
  onStartNewConversation: () => void;
}) {
  switch (currentTool.name) {
    case 'essays':
      return (
        <EssaysMainPage
          currentTool={currentTool}
          visibleHeight={visibleHeight}
          onStartNewConversation={onStartNewConversation}
        />
      );
    case 'primers':
      return (
        <PrimersMainPage
          currentTool={currentTool}
          visibleHeight={visibleHeight}
          contentWidth={pageContentWidth}
        />
      );
    default:
      return (
        <ChatMainPage
          currentTool={currentTool}
          visibleHeight={visibleHeight}
          contentWidth={pageContentWidth}
          inputTextarea={inputTextarea}
          setInputTextarea={setInputTextarea}
          onStartNewConversation={onStartNewConversation}
        />
      );
  }
}

export default function App() {
  const { authenticationStatus, loadedState } =
    useAuthenticationStatusAndLoadedState();

  const notReadyYetMessageIfExists = getNotLoadedYetMessageIfLoading(
    authenticationStatus,
    loadedState
  );

  if (notReadyYetMessageIfExists !== null) {
    return <div className="p1">{notReadyYetMessageIfExists}</div>;
  }

  assert(loadedState);

  return <AppWithLoadedState loadedState={loadedState} />;
}
