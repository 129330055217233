import classNames from 'classnames';
import { Button } from 'evergreen-ui';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { getNumTextareaLines } from './getNumTextareaLines';
import { isMobileOrTablet } from './isMobileOrTablet';
import { RowsConfig, RowsConfigType, assert } from './types';
import { useElementSize } from './useElementSize';

const SEND_BUTTON_HORIZONTAL_INDENT = 60;
const BUTTON_INDENT_FROM_TOP_AND_RIGHT = 5;

export function ResizingTextarea({
  value,
  onChange,
  onKeyDown,
  isSingleLine,
  shouldShowButton,
  buttonText,
  onSubmit,
  onSelect,
  setTextarea,
  rowsConfig = { type: RowsConfigType.GROW_WITH_TEXT },
  placeholder,
  style,
  shouldAutofocus = true,
  isFocused = false,
}: {
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isSingleLine: boolean;
  shouldShowButton: boolean;
  buttonText?: string;
  onSubmit?: (value: string, selectionIfExists: string | null) => void;
  onSelect?: (e: MouseEvent<HTMLTextAreaElement>) => void;
  setTextarea?: (node: HTMLTextAreaElement | null) => void;
  rowsConfig?: RowsConfig;
  placeholder?: string;
  style?: React.CSSProperties;
  shouldAutofocus?: boolean;
  isFocused?: boolean;
}) {
  const [textarea, _setTextarea] = useState<HTMLTextAreaElement | null>(null);
  const [sendButton, setSendButton] = useState<HTMLButtonElement | null>(null);

  const [textareaClientWidth, setTextareaClientWidth] = useState(0);
  useElementSize(textarea, (size) => {
    setTextareaClientWidth(size.width);
  });

  const paddingRight = shouldShowButton ? SEND_BUTTON_HORIZONTAL_INDENT : 8;

  const getNumLines = useCallback(() => {
    assert(rowsConfig.type === RowsConfigType.GROW_WITH_TEXT);

    if (textarea === null) {
      return 1;
    }

    const numLines = getNumTextareaLines(value, {
      clientWidth: textareaClientWidth,
      paddingTop: 8,
      paddingRight,
      paddingBottom: 8,
      paddingLeft: 8,
    });

    if (rowsConfig.min !== undefined && numLines < rowsConfig.min) {
      return rowsConfig.min;
    }

    if (rowsConfig.max !== undefined && numLines > rowsConfig.max) {
      return rowsConfig.max;
    }

    return numLines;
  }, [value, textarea, rowsConfig, textareaClientWidth, paddingRight]);

  if (shouldShowButton) {
    assert(onSubmit);
    assert(buttonText);
  }

  const isSubmitDisabled = value.length === 0;

  useEffect(() => {
    if (!isFocused || textarea === null) {
      return;
    }

    textarea.focus();
  }, [isFocused, textarea]);

  return (
    <div
      className={classNames(
        'flex',
        rowsConfig.type === RowsConfigType.GROW_WITH_TEXT
          ? ''
          : 'flex-column height-full'
      )}
    >
      <textarea
        autoFocus={shouldAutofocus}
        // @ts-ignore-next-line
        enterKeyHint={isSingleLine ? 'send' : 'return'}
        placeholder={placeholder}
        spellCheck={true}
        ref={(node) => {
          _setTextarea(node);

          if (!setTextarea) {
            return;
          }

          setTextarea(node);
        }}
        value={value}
        // rows={isSingleLine ? 1 : numLines}
        className="p1 border-box rounded width-full border-evergreen-ui"
        style={{
          height: '100%',
          resize: 'none',
          // required to stop scrolling from bubbling up elements we don't
          // want to scroll (see preventScrollingExceptFor())
          overscrollBehavior: 'contain',
          paddingRight,
          ...style,
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onSelect={(e: React.MouseEvent<HTMLTextAreaElement>) => {
          if (!onSelect) {
            return;
          }

          onSelect(e);
        }}
        onKeyDown={onKeyDown}
        // The only way I could fix iOS scrolling the whole body on input focus
        // https://gist.github.com/kiding/72721a0553fa93198ae2bb6eefaa3299
        onFocus={(e) => {
          // not needed on desktop, so stop the irritating flash
          if (!isMobileOrTablet()) {
            return;
          }

          const target = e.currentTarget;
          target.style.opacity = '0';
          setTimeout(() => (target.style.opacity = '1'), 80);
        }}
        {...(rowsConfig.type === RowsConfigType.GROW_WITH_TEXT
          ? { rows: getNumLines() }
          : {})}
      />

      {shouldShowButton && onSubmit && (
        <Button
          ref={(node: HTMLButtonElement) => setSendButton(node)}
          disabled={isSubmitDisabled}
          className={classNames('rounded no-user-select', {
            'gray-light1': isSubmitDisabled,
          })}
          appearance={isSubmitDisabled ? '' : 'primary'}
          style={{
            // marginTop, marginLeft and height all work together to nestle the button correctly
            marginTop: BUTTON_INDENT_FROM_TOP_AND_RIGHT,
            marginLeft: sendButton
              ? -sendButton.getBoundingClientRect().width -
                BUTTON_INDENT_FROM_TOP_AND_RIGHT
              : 0,
            height: 24,
          }}
          size="small"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            assert(textarea);
            onSubmit(value, getTextSelection(textarea));
            e.preventDefault();
          }}
        >
          <span
            className={classNames({
              'text-white': isSubmitDisabled,
            })}
          >
            {buttonText}
          </span>
        </Button>
      )}
    </div>
  );
}

export function getTextSelection(textarea: HTMLTextAreaElement) {
  return textarea.selectionStart !== textarea.selectionEnd
    ? textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
    : null;
}
