import { useAppContext } from './substantiate';
import { Tool } from './types';
import { capitalize, startCase } from 'lodash';
import classNames from 'classnames';
import { Text } from 'evergreen-ui';
import { isMobileOrTablet } from './isMobileOrTablet';

const areOnMobileOrTablet = isMobileOrTablet();

export function ToolSwitch({ currentTool }: { currentTool: Tool }) {
  const { state, dispatch } = useAppContext();

  return (
    <div className="flex justify-center width-full">
      {state.tools
        .filter((tool) => !areOnMobileOrTablet || tool.name !== 'essays')
        .map((tool: Tool) => {
          return (
            <Text
              key={tool.name}
              onClick={() => dispatch({ type: 'setTool', toolName: tool.name })}
              className={classNames(
                'flex justify-center mx1 pointer',
                tool.name === currentTool.name
                  ? 'text-blue border-bottom border-light'
                  : 'text-gray'
              )}
              style={{ paddingBottom: 6, borderWidth: 2 }}
            >
              {capitalize(startCase(tool.name))}
            </Text>
          );
        })}
    </div>
  );
}
