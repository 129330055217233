import { Text } from 'evergreen-ui';
import { useAppContext } from './substantiate';
import { RowsConfigType, Tool } from './types';
import { useElementSize } from './useElementSize';
import { ResizingTextarea } from './ResizingTextarea';
import { isMobileOrTablet } from './isMobileOrTablet';
import { useState } from 'react';

export function CustomizePage({
  visibleHeight,
  contentWidth,
  currentTool,
}: {
  visibleHeight: number;
  contentWidth: number;
  currentTool: Tool;
}) {
  const { dispatch } = useAppContext();

  const [elementPrecedingPage, setElementPrecedingPage] =
    useState<HTMLDivElement | null>(null);
  const [abovePageHeight, setAbovePageHeight] = useState(0);
  useElementSize(elementPrecedingPage, (rect) =>
    setAbovePageHeight(rect.bottom)
  );

  return (
    <div
      className="p1" // padding on each page (not whole app) so scrollbar has a gutter
    >
      <div ref={(node) => setElementPrecedingPage(node)}></div>
      <div
        id="customize-page"
        style={{
          position: 'fixed',
          top: abovePageHeight,
          bottom: window.innerHeight - visibleHeight,
          width: contentWidth,
          // required to stop scrolling from bubbling up elements we don't
          // want to scroll (see preventScrollingExceptFor())
          overscrollBehavior: 'contain',
        }}
      >
        <div className="flex flex-column">
          <label className="flex flex-column">
            <Text size="small" className="flex mb-half">
              Description of the bot and how it should respond to messages
            </Text>

            <ResizingTextarea
              value={currentTool.prompt}
              onChange={(prompt) =>
                dispatch({
                  type: 'setToolPrompt',
                  toolName: currentTool.name,
                  prompt,
                })
              }
              isSingleLine={false}
              shouldShowButton={false}
              rowsConfig={{
                type: RowsConfigType.GROW_WITH_TEXT,
                max: isMobileOrTablet() ? 5 : 20,
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
}
