import { useEffect, useState } from 'react';
import { _loadSharedState, _loadLocalState } from './substantiate';
import { AppState, AuthenticationStatus, Tool } from './types';
import { isAuthenticated, loadAccessKeyIdIfExists } from './authentication';
import { getInitialState } from './getInitialState';

export function useAuthenticationStatusAndLoadedState(): {
  authenticationStatus: AuthenticationStatus;
  loadedState: AppState | null;
} {
  const [authenticationStatus, setAuthenticationStatus] =
    useState<AuthenticationStatus>(null);

  const [loadedState, setLoadedState] = useState<AppState | null>(null);

  useEffect(() => {
    if (authenticationStatus !== null) {
      return;
    }

    const accessKeyId = loadAccessKeyIdIfExists();

    if (!accessKeyId) {
      setAuthenticationStatus('no-access-key-id');
      return;
    }

    setAuthenticationStatus('pending');

    isAuthenticated(accessKeyId).then((isAuthenticated: boolean) => {
      setAuthenticationStatus(
        isAuthenticated ? 'authenticated' : 'no-uses-left'
      );

      _loadSharedState(accessKeyId).then((tools: Array<Tool> | null) => {
        const localState = _loadLocalState() || getInitialState();
        localState.tools = tools ? tools : localState.tools;
        setLoadedState(localState);
      });
    });
  }, [authenticationStatus]);

  return { authenticationStatus, loadedState };
}
