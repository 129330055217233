export async function isAuthenticated(accessKeyId: string) {
  return (
    await fetch(`/authenticate?accessKeyId=${accessKeyId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
  ).ok;
}

export function storeAccessKeyIdIfExists() {
  const params = new URLSearchParams(window.location.search);
  const accessKeyId = params.get('accessKeyId');

  if (accessKeyId) {
    localStorage.setItem('accessKeyId', accessKeyId);
    console.log(`Current accessKeyId ${accessKeyId}`);
    window.location.href = '/'; // Remove access key from URL
  }
}

export function loadAccessKeyIdIfExists() {
  return localStorage.getItem('accessKeyId');
}
