import { Button, Text } from 'evergreen-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ResizingTextarea } from './ResizingTextarea';
import { useAppContext } from './substantiate';
import { RowsConfigType, TextUpdate, Tool, assert } from './types';
import { getConversationsForTool } from './query';
import { last } from 'lodash';
import { Messages } from './Messages';
import classNames from 'classnames';
import { useAppKeyboardShortcuts } from './useAppKeyboardShortcuts';
import { EssayTextarea, useEssayTextareaRemirror } from './EssayTextarea';
import { EditorState } from 'remirror';
import { TextSelection } from '@remirror/pm/state';
import { useGetInterruptibleResponseStreamed } from './getResponse';
import { useElementSize } from './useElementSize';

export function EssaysMainPage({
  currentTool,
  visibleHeight,
  onStartNewConversation,
}: {
  currentTool: Tool;
  visibleHeight: number;
  onStartNewConversation: () => void;
}) {
  const [focusedInput, setFocusedInput] = useState<string>('essayTextarea');

  const { state, dispatch } = useAppContext();
  const messagesRef = useRef<HTMLDivElement | null>(null);

  const conversations = getConversationsForTool(state, currentTool.name);
  const currentConversation = last(conversations);
  assert(currentConversation);
  const displayOrderConversation = currentConversation;

  // essay editor state lives up here so we can use it to mutate the text when
  // user presses insert/append buttons
  const _initialEssayManagerAndState = useEssayTextareaRemirror(
    currentTool.essayInput || ''
  );
  const essayManager = _initialEssayManagerAndState.manager;
  const [essayState, setEssayState] = useState(
    _initialEssayManagerAndState.state
  );

  const [clearElement, setClearElement] = useState<HTMLDivElement | null>(null);
  const [clearElementHeight, setClearElementHeight] = useState(0);
  const [clearElementWidth, setClearElementWidth] = useState(0);
  useElementSize(clearElement, (rect) => {
    setClearElementHeight(rect.bottom);
    setClearElementWidth(rect.width);
  });
  const aboveMessagesHeight = clearElementHeight;

  // When messages changes, scroll to the bottom
  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }

    messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
  }, [conversations, visibleHeight]);

  const getInterruptibleResponseStreamed =
    useGetInterruptibleResponseStreamed();

  const onSubmit = useCallback(
    (input: string) => {
      if (!input) {
        return;
      }

      dispatch({
        type: 'setInput',
        toolName: currentTool.name,
        input: '',
      });

      dispatch({
        type: 'addMessage',
        sender: 'user',
        text: input,
        toolName: currentTool.name,
      });

      dispatch({
        type: 'addMessage',
        sender: 'bot',
        text: '...',
        toolName: currentTool.name,
      });

      getInterruptibleResponseStreamed(
        currentTool,
        input,
        currentConversation,
        (output) => {
          dispatch({
            type: 'setMessage',
            messageIndex: currentConversation.length + 1,
            sender: 'bot',
            text: output,
            toolName: currentTool.name,
          });
        }
      );
    },
    [
      dispatch,
      currentTool,
      getInterruptibleResponseStreamed,
      currentConversation,
    ]
  );

  const onEditEssay = useCallback(
    (change: TextUpdate) => {
      const newEditorState = getEditorStateAfterEdit(essayState, change);

      setEssayState(newEditorState.state);

      dispatch({
        type: 'setEssayInput',
        toolName: currentTool.name,
        essayInput: newEditorState.state,
      });

      setFocusedInput('essayTextarea');
    },
    [currentTool.name, dispatch, essayState]
  );

  useAppKeyboardShortcuts([
    {
      shouldFire: (key, modifiers) => key === 'Tab' && modifiers.altKey,
      onFire: () => {
        setFocusedInput(
          focusedInput === 'essayTextarea' ? 'queryTextarea' : 'essayTextarea'
        );
      },
    },
    {
      shouldFire: (key, modifiers) => key === 'k' && modifiers.metaKey,
      onFire: onStartNewConversation,
    },
    {
      shouldFire: (key, modifiers) =>
        key === 'r' && modifiers.shiftKey && modifiers.metaKey,
      onFire: () => {
        const displayOrderIndexOfMessageToInsert =
          getDisplayOrderIndexOfMessageToInsert();
        if (displayOrderIndexOfMessageToInsert === -1) {
          return;
        }
        onEditEssay({
          type: 'replace',
          text: displayOrderConversation[displayOrderIndexOfMessageToInsert]
            .text,
        });
      },
    },
    {
      shouldFire: (key, modifiers) =>
        key === 'a' && modifiers.shiftKey && modifiers.metaKey,
      onFire: () => {
        const displayOrderIndexOfMessageToInsert =
          getDisplayOrderIndexOfMessageToInsert();
        if (displayOrderIndexOfMessageToInsert === -1) {
          return;
        }
        onEditEssay({
          type: 'after',
          text: displayOrderConversation[displayOrderIndexOfMessageToInsert]
            .text,
        });
      },
    },
  ]);

  const getDisplayOrderIndexOfMessageToInsert = useCallback(() => {
    return last(displayOrderConversation)?.sender === 'bot'
      ? displayOrderConversation.length - 1
      : -1;
  }, [displayOrderConversation]);

  return (
    <div className="p1 flex border-box height-full">
      <div className="height-full" style={{ flex: 2 }}>
        <EssayTextarea
          editorManager={essayManager}
          editorState={essayState}
          onChange={(essayInput: EditorState) => {
            setEssayState(essayInput);
            dispatch({
              type: 'setEssayInput',
              toolName: 'essays',
              essayInput: essayInput,
            });
          }}
          isFocused={focusedInput === 'essayTextarea'}
          onKeyDown={(e) => {
            if (e.key === 'Tab') {
              e.preventDefault();
              return;
            }

            if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
              const selection = essayState.doc
                .textBetween(essayState.selection.from, essayState.selection.to)
                .trim();

              if (selection) {
                dispatch({
                  type: 'addMessage',
                  sender: 'user',
                  text: selection,
                  toolName: currentTool.name,
                });
              }

              setFocusedInput('queryTextarea');
              return;
            }
          }}
          placeholder="When should I take an exploratory approach to building?"
        />
      </div>

      <div className="ml1 flex flex-column height-full" style={{ flex: 1 }}>
        <div ref={(node) => setClearElement(node)}>
          <ResizingTextarea
            value={currentTool.input || ''}
            onChange={(input: string) => {
              dispatch({
                type: 'setInput',
                input,
                toolName: currentTool.name,
              });
            }}
            onKeyDown={(e) => {
              // Just let enter key press add new line
              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }

              if (e.key === 'Enter') {
                onSubmit(currentTool.input.trim());
                e.preventDefault(); // stop return adding a newline to input
                return;
              }
            }}
            isSingleLine={false}
            shouldShowButton={true}
            buttonText="⤴"
            rowsConfig={{
              type: RowsConfigType.GROW_WITH_TEXT,
              min: 1,
              max: 5,
            }}
            onSubmit={onSubmit}
            placeholder="Earth's population?"
            shouldAutofocus={false}
            isFocused={focusedInput === 'queryTextarea'}
          />

          <div
            className={classNames({
              'border-box border-bottom border-evergreen-ui':
                currentConversation.length > 0,
            })}
          >
            {currentConversation.length > 0 && (
              <Text
                className="flex justify-end width-full py-half text-gray pointer"
                size="small"
                onClick={() => {
                  dispatch({
                    type: 'clearMessages',
                    toolName: currentTool.name,
                  });

                  setFocusedInput('queryTextarea');
                }}
              >
                Clear
              </Text>
            )}
          </div>
        </div>

        <div
          id="messages"
          ref={messagesRef}
          style={{
            position: 'fixed',
            top: aboveMessagesHeight,
            bottom: window.innerHeight - visibleHeight,
            width: clearElementWidth,
            overflow: 'auto',
            // required to stop scrolling from bubbling up elements we don't
            // want to scroll (see preventScrollingExceptFor())
            overscrollBehavior: 'contain',
          }}
        >
          <Messages
            conversation={displayOrderConversation}
            getMessageDecoration={(message, i) => {
              const displayOrderIndexOfMessageToInsert =
                getDisplayOrderIndexOfMessageToInsert();
              if (displayOrderIndexOfMessageToInsert !== i) {
                return null;
              }

              return (
                <div className="flex pt-half">
                  <Button
                    appearance="primary"
                    size="small"
                    onClick={() =>
                      onEditEssay({ type: 'replace', text: message.text })
                    }
                  >
                    Replace
                  </Button>

                  <Button
                    appearance="primary"
                    size="small"
                    className="ml1"
                    onClick={() =>
                      onEditEssay({ type: 'after', text: message.text })
                    }
                  >
                    After
                  </Button>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

function getEditorStateAfterEdit(editorState: EditorState, change: TextUpdate) {
  switch (change.type) {
    case 'replace': {
      const stateWithText = editorState.applyTransaction(
        editorState.tr.insertText(change.text)
      ).state;

      const stateWithNewSelection = stateWithText.applyTransaction(
        stateWithText.tr.setSelection(
          new TextSelection(
            stateWithText.doc.resolve(editorState.selection.from),
            stateWithText.doc.resolve(
              editorState.selection.from + change.text.length
            )
          )
        )
      );

      return stateWithNewSelection;
    }
    case 'after': {
      const stateWithText = editorState.applyTransaction(
        editorState.tr.insertText(
          change.text,
          editorState.selection.from,
          editorState.selection.from
        )
      ).state;

      const stateWithNewSelection = stateWithText.applyTransaction(
        stateWithText.tr.setSelection(
          new TextSelection(
            stateWithText.doc.resolve(editorState.selection.from),
            stateWithText.doc.resolve(
              editorState.selection.from + change.text.length
            )
          )
        )
      );

      return stateWithNewSelection;
    }
    default:
      throw new Error('Unknown change type');
  }
}
