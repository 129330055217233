import { EditorState } from 'remirror';
import { getConversationsForTool } from './query';
import { AppState, Sender } from './types';

export function _dispatch(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'setTool':
      return {
        ...state,
        toolName: action.toolName,
      };

    case 'setToolPrompt':
      return {
        ...state,
        tools: state.tools.map((tool) =>
          tool.name === action.toolName
            ? { ...tool, prompt: action.prompt }
            : tool
        ),
      };

    case 'setInput':
      return {
        ...state,
        tools: state.tools.map((tool) =>
          tool.name === action.toolName
            ? { ...tool, input: action.input }
            : tool
        ),
      };

    case 'setEssayInput':
      return {
        ...state,
        tools: state.tools.map((tool) =>
          tool.name === action.toolName
            ? { ...tool, essayInput: action.essayInput }
            : tool
        ),
      };

    case 'addMessage': {
      const conversations = getConversationsForTool(state, action.toolName).map(
        (conversation, i) => {
          if (
            i !==
            getConversationsForTool(state, action.toolName).length - 1
          ) {
            return conversation;
          }

          return conversation.concat({
            sender: action.sender,
            text: action.text,
            isError: action.isError,
          });
        }
      );

      return {
        ...state,
        conversationsByToolName: {
          ...state.conversationsByToolName,
          [action.toolName]: conversations,
        },
      };
    }

    case 'setMessage': {
      const conversations = getConversationsForTool(state, action.toolName).map(
        (conversation, i) => {
          if (
            i !==
            getConversationsForTool(state, action.toolName).length - 1
          ) {
            return conversation;
          }

          return conversation.map((message, i) => {
            if (i !== action.messageIndex) {
              return message;
            }

            return {
              ...message,
              sender: action.sender,
              text: action.text,
            };
          });
        }
      );

      return {
        ...state,
        conversationsByToolName: {
          ...state.conversationsByToolName,
          [action.toolName]: conversations,
        },
      };
    }

    case 'startNewConversation': {
      return {
        ...state,
        conversationsByToolName: {
          ...state.conversationsByToolName,
          [action.toolName]: getConversationsForTool(
            state,
            action.toolName
          ).concat([[]]),
        },
      };
    }

    case 'setIsConfigShowing':
      return {
        ...state,
        isConfigShowing: action.isConfigShowing,
      };

    case 'clearMessages':
      return {
        ...state,
        conversationsByToolName: {
          ...state.conversationsByToolName,
          [action.toolName]: [[]],
        },
      };

    case 'switchedAway':
      return {
        ...state,
        switchedAwayTimeIfExists: Date.now(),
      };

    case 'deleteLastConversation':
      return {
        ...state,
        conversationsByToolName: {
          ...state.conversationsByToolName,
          [action.toolName]: getConversationsForTool(
            state,
            action.toolName
          ).slice(
            0,
            getConversationsForTool(state, action.toolName).length - 1
          ),
        },
      };

    default:
      throw new Error('Invalid action');
  }
}

export type AppAction =
  | { type: 'setTool'; toolName: string }
  | { type: 'setInput'; toolName: string; input: string }
  | { type: 'setEssayInput'; toolName: string; essayInput: EditorState }
  | {
      type: 'addMessage';
      sender: Sender;
      text: string;
      toolName: string;
      isError?: true;
    }
  | {
      type: 'setMessage';
      sender: Sender;
      text: string;
      messageIndex: number;
      toolName: string;
      isError?: true;
    }
  | { type: 'setIsConfigShowing'; isConfigShowing: boolean }
  | { type: 'setToolPrompt'; toolName: string; prompt: string }
  | { type: 'clearMessages'; toolName: string }
  | { type: 'startNewConversationIfNoNewEmptyConversation' }
  | { type: 'startNewConversation'; toolName: string }
  | { type: 'switchedAway' }
  | { type: 'deleteLastConversation'; toolName: string };
