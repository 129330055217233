export function isElementVisible(el: HTMLElement) {
  let elRect = el.getBoundingClientRect();
  let elTop = elRect.top;
  let elBottom = elRect.bottom;
  let parent = el.parentNode as HTMLElement;

  // Check if bottom of the element is off the page
  if (elRect.bottom < 0) {
    return false;
  }

  // Check it's within the document viewport
  if (elTop > document.documentElement.clientHeight) {
    return false;
  }

  let parentRect;
  do {
    parentRect = parent.getBoundingClientRect();
    if (!(elBottom <= parentRect.bottom)) {
      return false;
    }

    // Check if the element is out of view due to a container scrolling
    if (elTop <= parentRect.top) {
      return false;
    }

    parent = parent.parentNode as HTMLElement;
  } while (parent !== document.body);

  return true;
}
