import { Converter } from 'showdown';
import showdownHighlight from 'showdown-highlight';

export const markdownConverter = new Converter({
  simplifiedAutoLink: true,
  extensions: [
    showdownHighlight({
      pre: false,
      auto_detection: true,
    }),
  ],
});
