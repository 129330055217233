import { last } from 'lodash';
import { AppAction } from './dispatch';
import { Conversation, assert } from './types';
import { useDocumentVisibility } from './useDocumentVisibility';

const SWITCH_AWAY_RETAIN_CONVERSATION_MILLISECONDS = 1000 * 60; // 1 minute

export function useNewConversationWhenSwitchedAwayForAWhile(
  toolName: string,
  conversations: Array<Conversation>,
  dispatch: (action: AppAction) => void,
  switchedAwayTimeIfExists?: number
) {
  // Start new conversation on exit
  useDocumentVisibility((isVisible: boolean) => {
    if (!isVisible) {
      dispatch({ type: 'switchedAway' });
      return;
    }

    const currentConversation = last(conversations);
    assert(currentConversation);

    if (currentConversation.length === 0) {
      return;
    }

    if (
      !switchedAwayTimeIfExists ||
      switchedAwayTimeIfExists + SWITCH_AWAY_RETAIN_CONVERSATION_MILLISECONDS >
        Date.now()
    ) {
      return;
    }

    dispatch({ type: 'startNewConversation', toolName });
  });
}
