import classNames from 'classnames';
import { Conversation, Message } from './types';
import { useMemo } from 'react';
import { Text } from 'evergreen-ui';
import { flatten } from 'lodash';
import { removePromptSignals } from './promptSignals';
import { markdownConverter } from './markdownConverter';

export function Messages({
  conversation,
  getMessageDecoration,
}: {
  conversation: Conversation;
  getMessageDecoration?: (
    message: Message,
    i: number,
    messages: Conversation
  ) => React.ReactNode | null;
}) {
  const renderedMessages = useMemo(() => {
    // Memo because highlighting is expensive and if the parent (App)
    // re-renders, all these messages get rerendered even though their props
    // haven't changed
    return (
      <RenderedConversation
        conversation={conversation}
        getMessageDecoration={getMessageDecoration}
      />
    );
  }, [conversation, getMessageDecoration]);

  return <>{renderedMessages}</>;
}

function RenderedConversation({
  conversation,
  getMessageDecoration,
}: {
  conversation: Conversation;
  getMessageDecoration?: (
    message: Message,
    i: number,
    messages: Conversation
  ) => React.ReactNode | null;
}) {
  return (
    <div className={classNames('height-full')}>
      {conversation.map((message, i) => {
        const formattedMessage = markdownConverter.makeHtml(
          removePromptSignals(message).text
        );

        return (
          <div
            key={i}
            className={classNames('px1 py-half rounded flex', {
              'gray-light2': message.sender === 'user',
            })}
          >
            <Text
              style={{
                fontSize: 13,
              }}
            >
              <p className="mr1">{message.sender === 'user' ? '🧑' : '👁'}</p>
            </Text>
            <Text>
              <div
                dangerouslySetInnerHTML={{ __html: formattedMessage }}
                style={{ color: message.isError ? 'red' : 'inherit' }}
              />

              {getMessageDecoration &&
                getMessageDecoration(message, i, conversation)}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

export function getMessageList(conversations: Array<Conversation>) {
  return flatten(conversations);
}
