import { Text } from 'evergreen-ui';

export function PageSwitchButton({
  isConfigShowing,
  setIsConfigShowing,
}: {
  isConfigShowing: boolean;
  setIsConfigShowing: (isConfigShowing: boolean) => void;
}) {
  if (isConfigShowing) {
    return (
      <Text
        className="pointer"
        size="small"
        onClick={() => setIsConfigShowing(false)}
      >
        &lt; Back
      </Text>
    );
  }

  return (
    <Text
      className="pointer"
      size="small"
      onClick={() => setIsConfigShowing(true)}
    >
      Customize
    </Text>
  );
}
