import { useEffect } from 'react';
import { assert } from './types';

export function useElementSize(
  elementRef: HTMLElement | null,
  onResize: (rect: DOMRect) => void
) {
  useEffect(() => {
    if (!elementRef) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      assert(elementRef);
      onResize(elementRef.getBoundingClientRect());
    });

    resizeObserver.observe(elementRef);

    return () => resizeObserver.disconnect();
  }, [elementRef, onResize]);
}
