import { Modifiers } from './types';
import { useGlobalKeyDown } from './useGlobalKeyDown';

export function useAppKeyboardShortcuts(
  shortcutMappings: Array<{
    shouldFire: (key: string, modifiers: Modifiers) => boolean;
    onFire: () => void;
  }>
) {
  useGlobalKeyDown((e) => {
    for (const { shouldFire, onFire } of shortcutMappings) {
      if (
        shouldFire(e.key, {
          shiftKey: e.shiftKey,
          ctrlKey: e.ctrlKey,
          metaKey: e.metaKey,
          altKey: e.altKey,
        })
      ) {
        onFire();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }
  });
}
