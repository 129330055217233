import React from 'react';
import { useReducer, useContext } from 'react';
import { AppAction } from './dispatch';
import { compress, decompress } from 'lz-string';
import { AppState, assert, Tool } from './types';
import { loadAccessKeyIdIfExists } from './authentication';

// Approach:
//
// Tools part of AppState stored on server so they can be shared
// between dev and prod. The rest locally.

export const LOCAL_STORAGE_KEY = 'meld';

export function useAppContext(): {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
} {
  const context = useContext(getAppContext<AppState, AppAction>());

  if (!context) {
    throw new Error('Context has not been instantiated');
  }

  return context;
}

export function useAppState(
  dispatchFn: (state: AppState, action: AppAction) => AppState,
  loadedState: AppState
): {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
  AppContext: React.Context<{
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
  }>;
} {
  const [state, dispatch] = useReducer((state: AppState, action: AppAction) => {
    const newState = dispatchFn(state, action);

    _persistState(newState);

    return newState;
  }, loadedState);

  globalizeState(window, state, dispatch);

  return {
    state,
    dispatch,
    AppContext: getAppContext<AppState, AppAction>(),
  };
}

let AppContext: unknown = null;

export function getAppContext<AppState, AppAction>(): React.Context<{
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
}> {
  if (!AppContext) {
    AppContext = React.createContext<{
      state: AppState;
      dispatch: React.Dispatch<AppAction>;
    } | null>(null);
  }

  return AppContext as React.Context<{
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
  }>;
}

function _persistState(state: AppState) {
  _persistLocalState(state);
  _persistSharedState(state.tools);
}

function _persistLocalState<AppState>(state: AppState) {
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY,
    compress(JSON.stringify(state))
  );
}

async function _persistSharedState(tools: Array<Tool>) {
  const accessKeyId = loadAccessKeyIdIfExists();

  if (!accessKeyId) {
    throw new Error('No access key when trying to persist tools');
  }

  await fetch(`/tools?accessKeyId=${accessKeyId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ tools }),
  });
}

export async function _loadSharedState(
  accessKeyId: string
): Promise<Array<Tool> | null> {
  const response = await (
    await fetch(`/tools?accessKeyId=${accessKeyId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
  ).json();

  return response.tools as Array<Tool> | null;
}

export function _loadLocalState(): AppState | null {
  const dataString = window.localStorage.getItem(LOCAL_STORAGE_KEY);

  if (dataString === null) {
    return null;
  }

  const decompressedDataString = decompress(dataString);

  assert(decompressedDataString !== null);

  return JSON.parse(decompressedDataString);
}

function globalizeState(
  window: Window,
  state: AppState,
  dispatch: React.Dispatch<AppAction>
) {
  // eslint-disable-next-line
  (window as any).substantiate = {
    state,
    dispatch,
    decompress: () => {
      return _loadLocalState();
    },
    update: (mutator: (currentState: AppState) => void) => {
      const currentState = _loadLocalState();
      assert(currentState);
      mutator(currentState);
      _persistState(currentState);
    },
    destroy: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      window.location.reload();
    },
  };
}

export function getDispatch(): React.Dispatch<AppAction> {
  // @ts-ignore:next-line
  return window.substantiate.dispatch;
}
