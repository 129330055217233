import { EditorState } from 'remirror';

export type AppState = {
  toolName?: string;
  tools: Array<Tool>;
  conversationsByToolName: { [toolName: string]: Array<Conversation> };
  isConfigShowing: boolean;
  switchedAwayTimeIfExists?: number;
};

export type Sender = 'user' | 'bot';

export type Conversation = Array<Message>;

export type Page = 'chat' | 'settings';

export enum RowsConfigType {
  GROW_WITH_TEXT = 'growWithText',
  FULL_HEIGHT = 'fullHeight',
}

export type RowsConfig =
  | { type: RowsConfigType.GROW_WITH_TEXT; min?: number; max?: number }
  | { type: RowsConfigType.FULL_HEIGHT };

export type Message = {
  sender: Sender;
  text: string;
  isError?: true;
};

export type OpenAiFormatMessage = {
  role: 'system' | 'user' | 'assistant';
  content: string;
};

export type Tool = {
  name: string;
  input: string;
  essayInput?: EditorState;
  prompt: string;
  userMessageTemplate?: string;
  maxTokens?: number;
  placeholder?: string;
};

export type AuthenticationStatus =
  | null
  | 'pending'
  | 'no-access-key-id'
  | 'no-uses-left'
  | 'authenticated';

export interface AccessKeyQuery {
  accessKeyId: string;
}

export interface ServerGptQuery {
  messages: Array<OpenAiFormatMessage>;
  temperature: number;
  maxTokens?: number;
  model?: string;
}

export type Modifiers = {
  shiftKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  altKey: boolean;
};

export type Selection = { start: number; end: number };

export type TextUpdate =
  | {
      type: 'replace';
      text: string;
    }
  | { type: 'after'; text: string };

export function assert(value: unknown, message?: string): asserts value {
  if (value === false || value === null || value === undefined) {
    throw new Error(`Assertion invalid${message ? `: ${message}` : ''}`);
  }
}
