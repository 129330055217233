import { useCallback, useEffect } from 'react';

export function useGlobalKeyDown(
  onKeyDownCallback: (e: KeyboardEvent) => void
) {
  const onKeyDown = useCallback(onKeyDownCallback, [onKeyDownCallback]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
}
