import { Text } from 'evergreen-ui';
import { useCallback, useEffect, useState } from 'react';
import { useGetInterruptibleResponseStreamed } from './getResponse';
import { removePromptSignals } from './promptSignals';
import { Tool, Message, assert, RowsConfigType } from './types';
import { markdownConverter } from './markdownConverter';
import { ResizingTextarea } from './ResizingTextarea';
import { useElementSize } from './useElementSize';

const INPUT_PREAMBLE = 'Give me a primer on… ';

export function PrimersMainPage({
  currentTool,
  visibleHeight,
  contentWidth,
}: {
  currentTool: Tool;
  visibleHeight: number;
  contentWidth: number;
}) {
  const [textarea, setTextarea] = useState<HTMLTextAreaElement | null>(null);
  const [input, setInput] = useState<string>('');

  const [clearElement, setClearElement] = useState<HTMLDivElement | null>(null);
  const [clearElementHeight, setClearElementHeight] = useState(0);
  useElementSize(clearElement, (rect) => setClearElementHeight(rect.bottom));

  const aboveStoryHeight = clearElementHeight;

  const [output, setOutput] = useState<Message>({
    sender: 'user',
    text: '',
  });

  const getInputWithoutPreamble = useCallback((inputWithPreamble: string) => {
    return inputWithPreamble.substring(INPUT_PREAMBLE.length);
  }, []);

  const getInputWithPreamble = useCallback((inputWithoutPreamble: string) => {
    return `${INPUT_PREAMBLE}${inputWithoutPreamble}`;
  }, []);

  const getInterruptibleResponseStreamed =
    useGetInterruptibleResponseStreamed();

  const onSubmit = useCallback(() => {
    (async function () {
      if (!input) {
        return;
      }

      assert(currentTool.userMessageTemplate);
      const input_ = currentTool.userMessageTemplate.replace(
        '$[subject]',
        input
      );

      getInterruptibleResponseStreamed(currentTool, input_, [], (output) => {
        setOutput({
          sender: 'bot',
          text: output,
        });
      });
    })();
  }, [currentTool, getInterruptibleResponseStreamed, input]);

  const constrainTextareaSelectionToInputWithoutPreamble = useCallback(() => {
    if (!textarea) {
      return;
    }

    textarea.setSelectionRange(
      Math.max(INPUT_PREAMBLE.length, textarea.selectionStart),
      Math.max(INPUT_PREAMBLE.length, textarea.selectionEnd)
    );
  }, [textarea]);

  useEffect(constrainTextareaSelectionToInputWithoutPreamble, [
    constrainTextareaSelectionToInputWithoutPreamble,
  ]);

  return (
    <div className="flex px1 width-full">
      <div className="width-full" ref={(node) => setClearElement(node)}>
        <ResizingTextarea
          setTextarea={setTextarea}
          value={getInputWithPreamble(input)}
          onChange={(inputWithPreamble: string) => {
            setInput(getInputWithoutPreamble(inputWithPreamble));
          }}
          isSingleLine={true}
          onSubmit={onSubmit}
          onSelect={constrainTextareaSelectionToInputWithoutPreamble}
          shouldShowButton={false}
          rowsConfig={{ type: RowsConfigType.GROW_WITH_TEXT, min: 1, max: 2 }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              onSubmit();
              e.preventDefault(); // stop return adding a newline to input
              return;
            }
          }}
          style={{
            paddingLeft: 0,
            border: 0,
            outline: 'none',
            caretColor: '#000',
          }}
        />
      </div>

      <div
        id="story"
        style={{
          position: 'fixed',
          top: aboveStoryHeight,
          bottom: window.innerHeight - visibleHeight,
          width: contentWidth,
          overflow: 'auto',
          // required to stop scrolling from bubbling up elements we don't
          // want to scroll (see preventScrollingExceptFor())
          overscrollBehavior: 'contain',
        }}
      >
        <Output output={output} />
      </div>
    </div>
  );
}

function Output({ output }: { output: Message }) {
  if (output && output.isError) {
    return (
      <Text>
        <div className="text-red">{output.text}</div>
      </Text>
    );
  }

  const formattedMessage = markdownConverter.makeHtml(
    removePromptSignals(output).text
  );

  return (
    <div>
      <Text>
        <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
      </Text>
    </div>
  );
}
