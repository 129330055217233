import { AppState, AuthenticationStatus } from './types';

export function getNotLoadedYetMessageIfLoading(
  authenticationStatus: AuthenticationStatus,
  loadedState: AppState | null
) {
  if (authenticationStatus === 'no-access-key-id') {
    return <>No access key</>;
  }

  if (authenticationStatus === 'no-uses-left') {
    return <>Access key has no uses left</>;
  }

  if (
    !authenticationStatus ||
    authenticationStatus === 'pending' ||
    !loadedState
  ) {
    return '';
  }

  return null;
}
