import classNames from 'classnames';
import { assert } from './types';
import { useEffect, useRef } from 'react';
import { isElementVisible } from './isElementVisible';
import { Text } from 'evergreen-ui';

export type ItemSelection = {
  index: number;
  action: 'mouseOver' | 'keyboard';
} | null;

export function Menu({
  items,
  itemSelection,
  setItemSelection,
  style,
}: {
  items: Array<{ text: string }>;
  itemSelection: ItemSelection;
  setItemSelection: (itemSelection: ItemSelection) => void;
  style?: React.CSSProperties;
}) {
  const selectedItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!itemSelection) {
      return;
    }

    assert(selectedItemRef.current);

    if (
      isElementVisible(selectedItemRef.current) ||
      itemSelection.action === 'mouseOver'
    ) {
      return;
    }

    selectedItemRef.current.scrollIntoView({
      block: 'nearest',
    });
  }, [itemSelection]);

  return (
    <div
      style={{
        boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.3)',
        borderColor: '#d8dae5',
        maxHeight: 196,
        overflowY: 'scroll',
        ...style,
      }}
      className="white flex flex-column border-box border rounded justify-between py-half"
    >
      {items.map((item, i) => {
        const isSelected = itemSelection?.index === i;

        return (
          <div
            key={i}
            ref={isSelected ? selectedItemRef : null}
            className={classNames('px1 pointer', {
              'gray-light2': isSelected && itemSelection.action !== 'mouseOver',
              'darken1-hover': itemSelection?.action !== 'keyboard',
            })}
            style={{ marginTop: 2, marginBottom: 2 }}
            onMouseMove={(e) => {
              setItemSelection({ index: i, action: 'mouseOver' });
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
              }}
            >
              <Text>{item.text}</Text>
            </div>
          </div>
        );
      })}
    </div>
  );
}
