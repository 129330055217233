// Prevents the bug where the whole window scrolls on mobile even if there's no hidden content.
//
// When the keyboard is open, iOS keeps the document the full height of the screen, and doesn't let you
// make it the same size as the visible area.  This means that if the user swipes, the document will
// scroll even if there's nothing down there. This is unnecessary because we do a bunch of work in App and AnswerMainPage to keep the content to the visible height (above the
// keyboard if it's open or full window height if not).
//
// This file works by basically killing all touchmoves (which cause scrolls).  The only ones it permits
// are ones on elements we want to scroll (like the messages list or the input textareas).
//
// To work, this requires setting overscroll-behavior: contain on the scrollable
// elements (otherwise scrolling to the limit of one of them will scroll the
// parent which we probably don't want to scroll).
//
// Note we need to use addEventListener because touchmove e.preventDefault()

import { useEffect } from 'react';

// seems to be broken in React when put directly on in JSX.
export function usePreventWholePageScrollingOnMobile(
  idsThatNeedToScroll: Array<string>,
  tagNamesThatNeedToScroll: Array<string>,
  visibleHeight: number,
  windowInnerHeight: number
) {
  useEffect(() => {
    function handleTouchMove(e: TouchEvent) {
      const target = e.target as HTMLElement;
      const scrollableElement = getScrollableElementIfExists(
        target,
        idsThatNeedToScroll,
        tagNamesThatNeedToScroll
      );

      // Permit scrolling if the current element has been specified as
      // scrollable and the element has more content than it can show.
      //
      // This means we preventDefault for scrollable elements that don't have
      // enough content to scroll. This is because, for some reason, a scroll in
      // this case causes the overscroll-behavior: contain behavior to be
      // ignored and the outer container (the whole document) to scroll
      //
      // We also permit scrolling if the visibleHeight equals windowInnerHeight
      // because this indicates the keyboard is closed and a) the problem of the
      // document scrolling that this whole thing solves isn't relevant and b) we
      // need to permit touchmove because the user might be trying to select text.
      if (
        (scrollableElement &&
          scrollableElement.scrollHeight > scrollableElement.clientHeight) ||
        visibleHeight === windowInnerHeight
      ) {
        return;
      }

      e.preventDefault();
    }

    document.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [
    idsThatNeedToScroll,
    tagNamesThatNeedToScroll,
    visibleHeight,
    windowInnerHeight,
  ]);
}

function getScrollableElementIfExists(
  element: HTMLElement | null,
  ids: Array<string>,
  tagNames: Array<string>
): HTMLElement | null {
  if (!element) {
    return null;
  }

  if (ids.includes(element.id) || tagNames.includes(element.tagName)) {
    return element;
  }

  return getScrollableElementIfExists(element.parentElement, ids, tagNames);
}
