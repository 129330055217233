import { useCallback, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ItemSelection, Menu } from './Menu';
import { useAppContext } from './substantiate';
import { Message, assert, Tool, RowsConfigType } from './types';
import { uniqBy } from 'lodash';
import { isMobileOrTablet } from './isMobileOrTablet';
import { getMessageList } from './Messages';
import { ResizingTextarea } from './ResizingTextarea';
import { useElementSize } from './useElementSize';
import { getConversationsForTool } from './query';

const MAX_AUTOCOMPLETE_INPUT_MESSAGES = 10;

export function AnswersInput({
  inputTextarea,
  setInputTextarea,
  onSubmit,
  placeholder,
  currentTool,
}: {
  inputTextarea: HTMLTextAreaElement | null;
  setInputTextarea: (node: HTMLTextAreaElement | null) => void;
  onSubmit: (input: string) => void;
  placeholder: string;
  currentTool: Tool;
}) {
  const { state, dispatch } = useAppContext();
  const [textareaWidth, setTextareaWidth] = useState<number | undefined>(
    undefined
  );
  const conversations = getConversationsForTool(state, currentTool.name);
  const messageList = getMessageList(conversations);
  const [itemSelection, setItemSelection] = useState<ItemSelection>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const updateInput = useCallback(
    (newInput: string) => {
      dispatch({
        type: 'setInput',
        toolName: currentTool.name,
        input: newInput,
      });

      setTimeout(() => {
        assert(inputTextarea);
        inputTextarea.setSelectionRange(newInput.length, newInput.length);
      });
    },
    [dispatch, inputTextarea, currentTool.name]
  );

  useElementSize(inputTextarea, (rect) => {
    setTextareaWidth(rect.width);
  });

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setItemSelection(null);
  }, []);

  const onSubmitFromInput = useCallback(
    (input: string) => {
      if (!input) {
        return;
      }
      onSubmit(input);
      updateInput('');
    },
    [onSubmit, updateInput]
  );

  return (
    <>
      <form action=".">
        <label>
          <div className="flex flex-column items-stretch">
            <ResizingTextarea
              value={currentTool.input}
              onChange={(input: string) => {
                dispatch({
                  type: 'setInput',
                  toolName: currentTool.name,
                  input,
                });
              }}
              onKeyDown={(e) => {
                // Just let enter key press add new line
                if (e.key === 'Enter' && e.shiftKey && !isMenuOpen) {
                  return;
                }

                if (e.key === 'Enter' && !isMenuOpen) {
                  onSubmitFromInput(currentTool.input.trim());
                  setItemSelection(null);
                  e.preventDefault(); // stop return adding a newline to input
                  return;
                }

                // Choose menu item and submit right away
                if (
                  e.key === 'Enter' &&
                  (e.metaKey || e.ctrlKey) &&
                  isMenuOpen
                ) {
                  assert(itemSelection);
                  onSubmitFromInput(
                    getUniqueUserMessagesInReverse(messageList)[
                      itemSelection.index
                    ].text
                  );
                  closeMenu();
                  e.preventDefault(); // stop return adding a newline to input
                  return;
                }

                if (e.key === 'Enter' && isMenuOpen) {
                  assert(itemSelection);
                  updateInput(
                    getUniqueUserMessagesInReverse(messageList)[
                      itemSelection.index
                    ].text
                  );
                  closeMenu();
                  e.preventDefault(); // stop return adding a newline to input
                  return;
                }

                if (e.key === 'Escape') {
                  closeMenu();
                  return;
                }

                assert(inputTextarea);
                const selectionIndex = inputTextarea.selectionStart;

                if (
                  getUniqueUserMessagesInReverse(messageList).length > 0 &&
                  ((e.key === 'ArrowUp' &&
                    isOnFirstLine(selectionIndex, currentTool.input)) ||
                    (e.key === 'ArrowDown' &&
                      isOnLastLine(selectionIndex, currentTool.input)))
                ) {
                  setIsMenuOpen(true);

                  const nextUserMessageIndex = getNextUserMessageIndex(
                    messageList,
                    itemSelection ? itemSelection.index : null,
                    e.key
                  );

                  if (nextUserMessageIndex === null) {
                    return;
                  }

                  const previousUserMessage = getPreviousUserMessage(
                    messageList,
                    nextUserMessageIndex
                  );

                  if (!previousUserMessage) {
                    return;
                  }

                  setItemSelection({
                    index: nextUserMessageIndex,
                    action: 'keyboard',
                  });
                }
              }}
              isSingleLine={isMobileOrTablet()}
              shouldShowButton={true}
              buttonText="⤴"
              onSubmit={onSubmitFromInput}
              setTextarea={setInputTextarea}
              rowsConfig={{ type: RowsConfigType.GROW_WITH_TEXT, max: 10 }}
              placeholder={placeholder}
            />
          </div>
        </label>
      </form>

      <Popover
        align="start"
        isOpen={isMenuOpen}
        positions={['bottom']}
        onClickOutside={closeMenu}
        content={
          <Menu
            items={getUniqueUserMessagesInReverse(messageList)}
            itemSelection={itemSelection}
            setItemSelection={setItemSelection}
            style={{
              width: textareaWidth,
              // Line up top of menu with bottom of input
              marginTop: -1,
            }}
          />
        }
      >
        <div></div>
      </Popover>
    </>
  );
}

function isOnFirstLine(selectionIndex: number, text: string) {
  return text.slice(0, selectionIndex).split('\n').length === 1;
}

function isOnLastLine(selectionIndex: number, text: string) {
  return text.slice(selectionIndex).split('\n').length === 1;
}

function getNextUserMessageIndex(
  messages: Array<Message>,
  userMessageIndex: number | null,
  key: 'ArrowUp' | 'ArrowDown'
): number | null {
  const userMessages = getUniqueUserMessagesInReverse(messages);

  if (userMessageIndex === null && key === 'ArrowDown') {
    return 0;
  }

  if (userMessageIndex === null) {
    return null;
  }

  const nextIndex = userMessageIndex + (key === 'ArrowDown' ? 1 : -1);

  if (nextIndex >= userMessages.length) {
    return null;
  }

  return nextIndex;
}

function getUniqueUserMessagesInReverse(messages: Array<Message>) {
  const userMessages = uniqBy(
    messages.filter((message) => message.sender === 'user').reverse(),
    'text'
  ).slice(0, MAX_AUTOCOMPLETE_INPUT_MESSAGES);

  return userMessages;
}

function getPreviousUserMessage(messages: Array<Message>, index: number) {
  const userMessage = getUniqueUserMessagesInReverse(messages)[index];

  if (!userMessage) {
    return null;
  }

  return userMessage;
}
