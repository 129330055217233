import { useEffect, useState } from 'react';

export function useWindowViewportSize() {
  const [size, setSize] = useState(window.visualViewport!.height);

  useEffect(() => {
    function handleResize() {
      setSize(window.visualViewport!.height);
    }

    window.visualViewport!.addEventListener('resize', handleResize);
    return () =>
      window.visualViewport!.removeEventListener('resize', handleResize);
  });

  return size;
}
