import { Text } from 'evergreen-ui';
import { ToolSwitch } from './ToolSwitch';
import { Tool } from './types';

export function Header({ currentTool }: { currentTool: Tool }) {
  return (
    <div className="px1 pt1 flex justify-between items-center no-user-select blue-light3 rounded">
      <Text is="h1" className="flex items-center pb1">
        <div className="flex items-center text-dark height-full">
          <a
            href="/"
            className="flex items-center pointer text-dark"
            style={{ textDecoration: 'none' }}
            tabIndex={-1}
          >
            <div className="pr-half" style={{ fontSize: 16 }}>
              👁️
            </div>
            <div className="stronger">Meld</div>
          </a>
        </div>
      </Text>

      <ToolSwitch currentTool={currentTool} />
    </div>
  );
}
