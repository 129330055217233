import { useEffect } from 'react';

// Fired when tab switched or closed, switch app on mobile, app minimized on
// desktop etc
export function useDocumentVisibility(cb?: (isVisible: boolean) => void) {
  useEffect(() => {
    function handleVisibilityChange() {
      const newIsVisible = !document.hidden;

      if (cb) {
        cb(newIsVisible);
      }
    }

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [cb]);
}
