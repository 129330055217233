import { Message } from './types';

export const INPUT_START = 'INPUT-START';
export const INPUT_END = 'INPUT-END';
export const OUTPUT_START = 'OUTPUT-START';
export const OUTPUT_END = 'OUTPUT-END';

// Addon added to all prompts
export const PROMPT_INPUT_OUTPUT_DELIMITER_DIRECTIONS = `Input starts with "${INPUT_START}" and ends with "${INPUT_END}". Output should start with "${OUTPUT_START}" and end with "${OUTPUT_END}".`;

export const INPUT_START_REGEX = new RegExp(`${INPUT_START}`);
export const INPUT_END_REGEX = new RegExp(`${INPUT_END}`);

export const OUTPUT_START_REGEX = new RegExp(`${OUTPUT_START}`);
export const OUTPUT_END_REGEX = new RegExp(`${OUTPUT_END}`);

export function addPromptSignals(message: Message) {
  const text =
    message.sender === 'bot'
      ? `${OUTPUT_START}${message.text}${OUTPUT_END}`
      : `${INPUT_START}${message.text}${INPUT_END}`;

  return { text, sender: message.sender };
}

export function removePromptSignals(message: Message) {
  const text =
    message.sender === 'bot'
      ? message.text
          .trim()
          .replace(OUTPUT_START_REGEX, '')
          .replace(OUTPUT_END_REGEX, '')
      : message.text
          .trim()
          .replace(INPUT_START_REGEX, '')
          .replace(INPUT_END_REGEX, '');

  return { text, sender: message.sender };
}
