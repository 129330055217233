import { BoldExtension } from '@remirror/extension-bold';
import { CalloutExtension } from '@remirror/extension-callout';
import { ItalicExtension } from '@remirror/extension-italic';
import { MarkdownExtension } from '@remirror/extension-markdown';
import { HeadingExtension } from '@remirror/extension-heading';
import {
  BulletListExtension,
  OrderedListExtension,
  TaskListExtension,
} from '@remirror/extension-list';
import { Remirror, useRemirror } from '@remirror/react-core';
import { EditorState, RemirrorManager } from 'remirror';
import { useExtension, useCommands, ReactExtensions } from '@remirror/react';
import { EventsExtension } from '@remirror/extension-events';
import { useEffect } from 'react';
import 'remirror/styles/all.css';
import './remirror-styles.css';

export function EssayTextarea({
  editorManager,
  editorState,
  onChange,
  onKeyDown,
  onKeyPress,
  isFocused,
  placeholder,
}: {
  editorManager: RemirrorManager<
    ReactExtensions<
      | BoldExtension
      | ItalicExtension
      | MarkdownExtension
      | CalloutExtension
      | BulletListExtension
      | OrderedListExtension
      | TaskListExtension
      | HeadingExtension
    >
  >;
  editorState: EditorState;
  onChange: (value: EditorState) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  isFocused: boolean;
  placeholder?: string;
}) {
  return (
    <Remirror
      onChange={(e) => {
        onChange(e.state);
      }}
      hooks={getHooks({ onKeyDown, onKeyPress, isFocused })}
      manager={editorManager}
      state={editorState}
      classNames={['px1 py-half border-evergreen-ui border border-box big']}
      placeholder={placeholder}
    />
  );
}

export function useEssayTextareaRemirror(initialState: EditorState | string) {
  const { manager, state } = useRemirror({
    extensions: () => {
      return [
        new BoldExtension(),
        new ItalicExtension(),
        new MarkdownExtension(),
        new CalloutExtension({ defaultType: 'warn' }),
        new BulletListExtension(),
        new OrderedListExtension(),
        new TaskListExtension(),
        new HeadingExtension(),
      ];
    },

    // Set the initial content.
    content: typeof initialState === 'string' ? initialState : initialState.doc,

    // Should be able to set selection here but it doesn't work for some reason
    selection: undefined,

    // Set the string handler which means the content provided will be
    // automatically handled as html.
    // `markdown` is also available when the `MarkdownExtension`
    // is added to the editor.
    stringHandler: 'markdown',
  });

  return { manager, state };
}

function getHooks({
  onKeyDown,
  onKeyPress,
  isFocused,
}: {
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  isFocused: boolean;
}) {
  return [
    // Bind key bindings
    () => {
      const commands = useCommands();

      useExtension(
        EventsExtension,
        ({ addHandler }) => {
          return addHandler('keydown', (e) => {
            if (onKeyDown) {
              // let the parent handle the event first
              onKeyDown(e);
            }

            // Super weird - only works for keydown and when not checking for
            // shift (even though it's pressed)
            if (e.key === 'y' && !e.shiftKey && e.metaKey) {
              commands.redo();
            }
          });
        },
        [onKeyDown]
      );

      useExtension(
        EventsExtension,
        ({ addHandler }) => {
          return addHandler('keypress', (e) => {
            if (onKeyPress) {
              // let the parent handle the event first
              onKeyPress(e);
            }

            // Currently no internal keypress handlers
          });
        },
        []
      );
    },

    // Enact isFocused prop
    () => {
      const commands = useCommands();

      useEffect(() => {
        if (!isFocused) {
          return;
        }

        commands.focus();
        // complains isFocused not a dependency, but it is
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [commands, isFocused]);
    },
  ];
}
