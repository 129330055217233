// Get number of wrapped lines in textarea
export function getNumTextareaLines(
  text: string,
  realTextareaAttributes: {
    clientWidth: number;
    paddingTop: number;
    paddingRight: number;
    paddingBottom: number;
    paddingLeft: number;
  }
) {
  const textarea = document.createElement('textarea');
  const { clientWidth, paddingTop, paddingRight, paddingBottom, paddingLeft } =
    realTextareaAttributes;

  // These need to match the real textarea
  textarea.style.fontSize = '14px';
  textarea.style.padding = '8';

  textarea.rows = 1;
  textarea.style.position = 'absolute';
  textarea.style.top = '-3000px';
  textarea.style.left = '-3000px';
  textarea.style.opacity = '0';
  textarea.style.resize = 'none';
  textarea.style.border = 'none';
  textarea.style.padding = '8px';
  textarea.style.lineHeight = '1rem';
  textarea.style.paddingTop = `${paddingTop}px`;
  textarea.style.paddingRight = `${paddingRight}px`;
  textarea.style.paddingBottom = `${paddingBottom}px`;
  textarea.style.paddingLeft = `${paddingLeft}px`;
  textarea.style.margin = '0';
  textarea.style.whiteSpace = 'pre-wrap';
  textarea.style.overflowWrap = 'break-word';
  textarea.value = text;
  textarea.style.boxSizing = 'border-box';
  textarea.style.width = clientWidth - 18 + 'px';

  document.body.appendChild(textarea);
  const numLines = Math.floor(textarea.scrollHeight / (14 * 1.16));
  document.body.removeChild(textarea);

  return numLines;
}
