import { useAppContext } from './substantiate';
import { assert, Conversation, Tool } from './types';
import { AnswersInput } from './AnswersInput';
import { Messages } from './Messages';
import { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { useElementSize } from './useElementSize';
import { Text } from 'evergreen-ui';
import classNames from 'classnames';
import { last, truncate } from 'lodash';
import { getConversationsForTool } from './query';
import { useNewConversationWhenSwitchedAwayForAWhile } from './useNewConversationWhenSwitchedAwayForAWhile';
import { useAppKeyboardShortcuts } from './useAppKeyboardShortcuts';
import { useGetInterruptibleResponseStreamed } from './getResponse';

export function ChatMainPage({
  currentTool,
  visibleHeight,
  contentWidth,
  inputTextarea,
  setInputTextarea,
  onStartNewConversation,
}: {
  currentTool: Tool;
  visibleHeight: number;
  contentWidth: number;
  inputTextarea: HTMLTextAreaElement | null;
  setInputTextarea: (node: HTMLTextAreaElement | null) => void;
  onStartNewConversation: () => void;
}) {
  const { state, dispatch } = useAppContext();
  const conversations = getConversationsForTool(state, currentTool.name);

  const messagesRef = useRef<HTMLDivElement | null>(null);

  const [clearElement, setClearElement] = useState<HTMLDivElement | null>(null);
  const [clearElementHeight, setClearElementHeight] = useState(0);
  useElementSize(clearElement, (rect) => setClearElementHeight(rect.bottom));

  useAppKeyboardShortcuts([
    {
      shouldFire: (key, modifiers) => key === 'k' && modifiers.metaKey,
      onFire: onStartNewConversation,
    },
  ]);

  const aboveMessagesHeight = clearElementHeight;

  const currentConversation = last(conversations);
  assert(currentConversation);
  const displayOrderConversation = currentConversation;

  const previousConversationIfExists = conversations[conversations.length - 2];

  const { switchedAwayTimeIfExists } = state;
  useNewConversationWhenSwitchedAwayForAWhile(
    currentTool.name,
    conversations,
    dispatch,
    switchedAwayTimeIfExists
  );

  // When messages or visibleHeight aboveMessagesHeight changes, scroll to the
  // bottom
  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }

    messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
  }, [conversations, visibleHeight, aboveMessagesHeight]);

  const getInterruptibleResponseStreamed =
    useGetInterruptibleResponseStreamed();

  const onSubmit = useCallback(
    (input: string) => {
      if (!input) {
        return;
      }

      dispatch({
        type: 'addMessage',
        sender: 'user',
        text: input,
        toolName: currentTool.name,
      });

      dispatch({
        type: 'addMessage',
        sender: 'bot',
        text: '...',
        toolName: currentTool.name,
      });

      getInterruptibleResponseStreamed(
        currentTool,
        input,
        currentConversation,
        (output) => {
          dispatch({
            type: 'setMessage',
            messageIndex: currentConversation.length + 1,
            sender: 'bot',
            text: output,
            toolName: currentTool.name,
          });
        }
      );
    },
    [
      dispatch,
      currentTool,
      getInterruptibleResponseStreamed,
      currentConversation,
    ]
  );

  return (
    <div
      className="p1" // padding on each page (not whole app) so scrollbar has a gutter
    >
      <div className="flex-none">
        <AnswersInput
          inputTextarea={inputTextarea}
          setInputTextarea={setInputTextarea}
          onSubmit={onSubmit}
          placeholder={
            currentConversation.length === 0
              ? currentTool.placeholder ?? 'How can I help?'
              : 'Reply...'
          }
          currentTool={currentTool}
        />
      </div>

      <div
        className={classNames({
          'border-box border-bottom border-evergreen-ui':
            currentConversation.length > 0,
        })}
      >
        <div className="py-half" ref={(node) => setClearElement(node)}>
          {currentConversation.length > 0 ? (
            <Text
              className="flex justify-end width-full text-gray pointer"
              size="small"
              onClick={onStartNewConversation}
            >
              Clear
            </Text>
          ) : previousConversationIfExists?.length > 0 ? (
            <Text
              className="flex justify-end width-full text-gray pointer"
              size="small"
              onClick={() => {
                dispatch({
                  type: 'deleteLastConversation',
                  toolName: currentTool.name,
                });
              }}
            >
              <ConversationShortSummary
                conversation={previousConversationIfExists}
              />
            </Text>
          ) : null}
        </div>

        <div
          id="messages"
          ref={messagesRef}
          style={{
            position: 'fixed',
            top: aboveMessagesHeight,
            bottom: window.innerHeight - visibleHeight,
            width: contentWidth,
            overflow: 'auto',
            // required to stop scrolling from bubbling up elements we don't
            // want to scroll (see preventScrollingExceptFor())
            overscrollBehavior: 'contain',
          }}
        >
          <Messages conversation={displayOrderConversation} />
        </div>
      </div>
    </div>
  );
}

function ConversationShortSummary({
  conversation,
}: {
  conversation: Conversation;
}) {
  const lastMessage = last(conversation);
  assert(lastMessage);

  const directions = <span className="text-blue">Resume</span>;

  const trimmedMessage = lastMessage.text.trim();

  if (trimmedMessage.length > 0) {
    return (
      <>
        {directions}: {truncate(trimmedMessage, { length: 40 })}
      </>
    );
  }

  return directions;
}
